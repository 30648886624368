import { Avatar, Box, Checkbox } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../..";
import EllipsisContent from "../../../../../Common/Component/EllipsisContent";

const PlayerCards = (props: any) => {
  const { selectedPlayer, setSelectedPlayer, channel }: any =
    useContext(UserContext);

  return (
    <>
      {props.playersName.map((playersname: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid #ccc",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <EllipsisContent text={playersname} />
          </Box>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPlayer([...selectedPlayer, playersname]);
              } else {
                const index = selectedPlayer.findIndex(
                  (element: any) => element == playersname
                );
                if (index != -1) {
                  const teamData = selectedPlayer.filter(
                    (e: any, ind: any) => ind != index
                  );

                  setSelectedPlayer(teamData);
                }
              }
            }}
            checked={selectedPlayer.includes(playersname) ? true : false}
          />
        </Box>
      ))}
    </>
  );
};

export default PlayerCards;
