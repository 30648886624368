import HttpService from "../../hooks/Https-services";
import { APIRoutes } from "../../utils/constants";

export const PersonalChannelApi = {
  GetVideoList(route: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.get(route);
  },
  GetLiniusAccessToken(route: any) {
    //return HttpService.get("todos/1");
    return HttpService.get(route);
  },
};
