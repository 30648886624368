import React, { ReactElement, useCallback, useContext, useState } from "react";
import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
//@ts-ignore

import { PlayerModule as AnalyticsModule } from "bitmovin-analytics";
import { PlayerEvent, Player, ViewMode } from "bitmovin-player";

import "bitmovin-player/bitmovinplayer-ui.css";

import { useSelector, shallowEqual } from "react-redux";

//@ts-ignore
import { UIFactory } from "bitmovin-player/bitmovinplayer-ui";

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import LoadingContext from "../../../../Common/Contex/LoadingContex";
import useCommonDetails from "../../../../Common/Hooks/useCommonDetails";
import HttpService from "../../../../hooks/Https-services";
import useConfig from "../../../../hooks/useConfig";
import {
  setBitmovinToSdk,
  groupSeek,
} from "../../../../service/SynchronizeService";
import { APIRoutes } from "../../../../utils/constants";
import { off, on } from "../../../../service/PubSub";
import { WatchContex } from "../../../Watch/watch";

declare global {
  interface Window {
    bitmovin: any;
  }
}

interface bitMovingPlayerProps {
  videoUrl: string;
  setRunning: any;
  playerRef: any;
  autoPlay: boolean;
  setIsSeeking: any;
  sideBarComponent?: ReactElement;
  checked: boolean;
  handleChangeIconAction: any;
  setCurrentTime: any;
  pageData: any;
  page: any;
  videoList: any[];
  currentVideoData: any;
  setCurrentVideoData: any;
  setActiveClip: any;
  setPage: any;
  accessToken: any;
}
const BitMovingPlayerLinius: React.FC<bitMovingPlayerProps> = ({
  videoUrl,
  handleChangeIconAction,
  playerRef,
  setCurrentTime,
  setIsSeeking,
  pageData,
  page,
  currentVideoData,
  setCurrentVideoData,
  setActiveClip,
  setPage,
  checked,
  autoPlay,
  sideBarComponent,
  setRunning,
  videoList,
  accessToken,
}) => {
  const startTimeRef = useRef<number>(0);

  const isPlayingRef = useRef<boolean>(false);

  const intervalRef = useRef<any>(null);

  const { gameId }: any = useParams();
  const userDetails = useCommonDetails();

  const userAgent = navigator.userAgent;
  const configData = useConfig();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isSideBarClosed, setIsSideBarClosed] = useState(false);
  const playerId = configData.jwPlayerID;
  // Event handler for seek end
  const handleSeekEnd = () => {
    setIsSeeking();
  };
  const handleClose = () => {
    const currentDiv = document.getElementById("sidebar-video-player");
    const currentDivExpand = document.getElementById("expand-button");
    if (currentDiv) {
      currentDiv.style.maxHeight = "0px";
      currentDiv.style.maxWidth = "0px";
      currentDiv.style.transition = "0.2s all ease-in";
    }
    if (currentDivExpand) {
      currentDivExpand.style.top = isMobile ? "40px" : "20px";
      currentDivExpand.style.right = "20px";
      currentDivExpand.style.rotate = "90deg";
      currentDivExpand.style.transition = "0.2s all ease-in";
    }
    setIsSideBarClosed(true);
  };
  const handleOpen = () => {
    const currentDiv = document.getElementById("sidebar-video-player");
    const currentDivExpand = document.getElementById("expand-button");
    if (currentDiv) {
      currentDiv.style.maxHeight = isTab ? "80%" : "87%";
      currentDiv.style.maxWidth = isMobile ? "250px" : "28%";
      currentDiv.style.transition = "0.2s all ease-in";
    }
    if (currentDivExpand) {
      currentDivExpand.style.top = "80px";
      currentDivExpand.style.rotate = "270deg";
      currentDivExpand.style.right = isMobile ? "240px" : "27%";
      currentDivExpand.style.transition = "0.2s all ease-in";
    }
    setIsSideBarClosed(false);
  };
  const room: any = useSelector(
    ({ roomDetails }: any) => roomDetails?.roomDetails,
    shallowEqual
  );
  const {
    openColleps,
    receivedMessage,
    setReceivedMessage,
    errorStyle,
    setReceivedImage,
    receivedImage,
    CloseColleps,

    setGameId,
  }: any = useContext(LoadingContext);
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("913"));
  const isTab = useMediaQuery((theme: any) => theme.breakpoints.down("1025"));
  const getIp = async () => {
    try {
      const ip: any = await fetch("https://api.ipify.org?format=json");
      const ip2 = await ip.json();
      localStorage.setItem("ip", ip2.ip);
    } catch (error) {
      localStorage.setItem("ip", "0.0.0.0");
    }
  };
  const [isVisible, setIsVisible] = useState(true);

  const intervalRefError: any = useRef(null);
  const timeRef: any = useRef(null);
  useEffect(() => {
    const clearExistingInterval = () => {
      if (intervalRefError.current) {
        clearInterval(intervalRefError.current);
        intervalRefError.current = null; // Clear ref
      }
    };

    if (errorStyle?.IsPause) {
      clearExistingInterval();

      intervalRefError.current = setInterval(() => {
        setIsVisible((prevVisible) => !prevVisible);
      }, (errorStyle?.Time + 10) * 1000);
    } else {
      clearExistingInterval();
    }

    return () => clearExistingInterval();
  }, [errorStyle?.IsPause, errorStyle?.Time]);
  useEffect(() => {
    if (!isVisible && errorStyle?.IsPause) {
      if (timeRef.current) {
        clearInterval(timeRef.current);
        timeRef.current = null;
      }
      timeRef.current = setTimeout(() => {
        setIsVisible(true); // Show the image again after 10 seconds
      }, 10000); // Hide for 10 seconds

      return () => clearTimeout(timeRef.current); // Clear the timeout when component unmounts or visibility changes
    }
  }, [isVisible, errorStyle?.IsPause]);
  const handleOpenDiaLog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    // Function to initialize JW Player
    getIp();
    const onFullscreenEnter = () => setIsFullscreen(true);
    const onFullscreenExit = () => setIsFullscreen(false);

    setGameId(gameId);
    var conf: any = {
      key: configData?.bitMovinKey,
      location: {
        ui: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
        ui_css: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
      },
      onFullscreenEnter: () => {
        onFullscreenEnter();
      },
      onFullscreenExit: () => {
        onFullscreenExit();
      },
      analytics: {
        key: configData.analysticBitmovinKey,

        customUserId: userDetails.UserID,
      },
      network: {
        preprocessHttpRequest: function (type: any, request: any) {
          if (type === "manifest/hls/master") {
            request.headers["Authorization"] = `Bearer ${accessToken}`;
            request.headers["x-Api-Key"] = configData.xApiKey;
          }
          if (type === "manifest/hls/variant") {
            request.headers["Authorization"] = `Bearer ${accessToken}`;
            request.headers["x-Api-Key"] = configData.xApiKey;
          }
          return Promise.resolve(request);
        },
      },
      playback: {
        autoplay: true,
      },
      cast: {
        enable: true,
      },
    };
    var executed = false;
    const findCurrentClipTime = (el: any, index: any) => {
      let sum = 0;
      for (var i = 0; i < index; i++) {
        sum += videoList[i].duration;
      }
      return sum;
    };

    var source = {
      hls: `${configData.liniusUrl}/assembly/hls?id=${videoList
        ?.map((el: any) => el?.assetId)
        .join(",")}&o=${videoList
        ?.map((el: any) => el?.offset)
        .join(",")}&d=${videoList?.map((el: any) => el?.duration).join(",")}`,
    };
    Player.addModule(AnalyticsModule);
    playerRef.current = new Player(
      document.getElementById("linus-bitmovin-player") as any,
      conf
    );

    let firstSeek = true;
    // Setup the player
    playerRef.current.load(source).then(() => {
      room?.video && setBitmovinToSdk(playerRef.current);
    });
    playerRef.current?.on(PlayerEvent.Play, () => {
      startTimeRef.current = new Date().getTime();
      isPlayingRef.current = true;
      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      setRunning(true);
    });

    playerRef.current.on(PlayerEvent.PlaybackFinished, () => {
      const findIndex = videoList.findIndex(
        (el: any) => el.id == currentVideoData.id
      );
      if (findIndex == videoList.length - 1) {
        return;
      }
      if (!pageData?.last) {
        setPage(page + 1);
      } else {
        setPage(1);
      }
    });
    playerRef.current?.on(PlayerEvent.Seeked, function () {
      handleSeekEnd();

      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      groupSeek();
    });
    playerRef.current?.on(PlayerEvent.ViewModeChanged, function () {
      if (playerRef.current.getViewMode() === ViewMode.Fullscreen) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    });

    playerRef.current?.on(PlayerEvent.TimeChanged, function () {
      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }
      var hasPlayedTime = playerRef.current.getCurrentTime("relativetime");
      setCurrentTime(hasPlayedTime);

      const findIndex = videoList.findIndex(
        (el: any, index: any) =>
          hasPlayedTime >= findCurrentClipTime(el, index) &&
          hasPlayedTime < findCurrentClipTime(el, index) + el?.duration
      );
      if (findIndex != -1) {
        setActiveClip(videoList[findIndex]?.id);
      }

      groupSeek();
    });

    const handleGroupPause = () => playerRef.current.pause();
    const handleGroupPlay = () => playerRef.current.play();
    const handleGroupMute = () => playerRef.current.mute();
    const handleGroupUnmute = () => playerRef.current.unmute();
    const handleGroupVolume = (volume: number) =>
      playerRef.current.setVolume(volume * 100);
    const handleGroupSeek = (delta: number) =>
      playerRef.current.seek(
        Math.max(playerRef.current.getCurrentTime() + delta / 1000, 0)
      );

    on("group_pause", handleGroupPause);
    on("group_play", handleGroupPlay);
    on("group_mute", handleGroupMute);
    on("group_unmute", handleGroupUnmute);
    on("group_volume", handleGroupVolume);
    on("group_seek", handleGroupSeek);

    playerRef.current?.on(PlayerEvent.Paused, () => {});

    const findBowser = () => {
      if (
        userAgent.indexOf("Opera") !== -1 ||
        userAgent.indexOf("OPR") !== -1
      ) {
        return "Opera";
      } else if (userAgent.indexOf("Edge") !== -1) {
        return "Edge";
      } else if (userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
      } else if (userAgent.indexOf("Safari") !== -1) {
        return "Safari";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
      } else if (userAgent.indexOf("MSIE") !== -1) {
        return "Internet Explorer";
      } else {
        return "Unknown";
      }
    };

    return () => {
      clearInterval(intervalRef.current);

      playerRef.current.unload();
      off("group_pause", handleGroupPause);
      off("group_play", handleGroupPlay);
      off("group_mute", handleGroupMute);
      off("group_unmute", handleGroupUnmute);
      off("group_volume", handleGroupVolume);
      off("group_seek", handleGroupSeek);
    };
  }, [playerId, videoUrl, room.video, videoList]);
  useEffect(() => {
    if (isFullscreen && isMobile) {
      setIsSideBarClosed(false);
      handleOpen();
    } else if (isMobile) {
      setIsSideBarClosed(true);
      handleClose();
    } else {
      setIsSideBarClosed(false);
      handleOpen();
    }
  }, [isMobile, checked, isFullscreen]);
  return (
    <Box sx={{ position: "relative" }}>
      <Box className="player-container" id={"linus-bitmovin-player"}>
        {checked && (
          <IconButton
            id="expand-button"
            onClick={
              isMobile && !isFullscreen
                ? handleOpenDiaLog
                : isSideBarClosed
                ? handleOpen
                : handleClose
            }
            sx={{
              rotate: "270deg",
              position: "absolute",
              right: isMobile ? "240px" : "27%",
              top: 80,
              zIndex: 101,
            }}
          >
            <ExpandCircleDownIcon
              sx={{ color: configData.tertiary, fontSize: 32 }}
            />
          </IconButton>
        )}
        {((checked && !isMobile) || (checked && isFullscreen && isMobile)) && (
          <Box
            id="sidebar-video-player"
            sx={{
              position: "absolute",
              zIndex: 100,
              maxHeight: isTab ? 0.8 : 0.87,
              maxWidth: isMobile ? "250px" : "28%",
              width: 1,
              right: 4,
              height: 1,
              top: 4,
              bottom: 0,
              overflow: "auto",
            }}
          >
            {sideBarComponent}
          </Box>
        )}
        {openColleps && (
          <Box
            sx={{
              position: "absolute",
              height:
                errorStyle?.Type === "text"
                  ? "30px"
                  : { xs: "50px", lg: "100px" },
              ...(errorStyle?.Placement === "top"
                ? { top: 0 }
                : errorStyle?.Placement === "bottom"
                ? { bottom: 0 }
                : {
                    bottom: 0,
                    top: {
                      xs:
                        errorStyle?.Type === "text"
                          ? "calc(50% - 15px)"
                          : "40%",
                    },
                  }),
              mx: "auto",
              left: 0,
              right: 0,
              zIndex: 100,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",

              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: 1,
                overflow: "hidden",
                justifyContent: !errorStyle?.IsPause ? "flex-start" : "center",
                display: "flex",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: !errorStyle?.IsPause ? "100%" : "0px",
                  height: 1,
                  width: "max-content",
                  boxSizing: "border-box",

                  animation: !errorStyle?.IsPause
                    ? `ticker-scroll ${
                        (18 * 50) / errorStyle?.Speed
                      }s linear infinite`
                    : "",
                }}
              >
                {" "}
                <style>
                  {`
          @keyframes ticker-scroll {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }
        `}
                </style>
                {errorStyle?.Type === "text" ? (
                  <Typography
                    onClick={() => {
                      if (errorStyle?.Linking === "open_in_new_tab") {
                        window.open(errorStyle?.Link, "_blank");
                      } else if (errorStyle?.Linking === "open_action_menu") {
                        handleChangeIconAction();
                      }
                    }}
                    sx={{
                      color: "white",
                      fontStyle: "normal",
                      textTransform: "none",
                      transition: "opacity 2s ease-in-out",
                      opacity: isVisible ? 1 : 0,
                    }}
                  >
                    {receivedMessage}
                  </Typography>
                ) : (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img
                    onClick={() => {
                      if (errorStyle?.Linking === "open_in_new_tab") {
                        window.open(errorStyle?.Link, "_blank");
                      } else if (errorStyle?.Linking === "open_action_menu") {
                        handleChangeIconAction();
                      }
                    }}
                    src={receivedImage}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      cursor: "pointer",
                      transition: "opacity 2s ease-in-out",
                      opacity: isVisible ? 1 : 0,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              the feature is only available when the video player is in full
              screen mode
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  bgcolor: configData.primary,
                },
              }}
              onClick={() => {
                playerRef?.current?.setViewMode("fullscreen");
                handleCloseDialog();
              }}
            >
              Open Fullscreen
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default BitMovingPlayerLinius;
