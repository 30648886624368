import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//@ts-ignore

import { PlayerModule as AnalyticsModule } from "bitmovin-analytics";
import { PlayerEvent, Player, ViewMode } from "bitmovin-player";

import "bitmovin-player/bitmovinplayer-ui.css";

//@ts-ignore

import { Box, useMediaQuery } from "@mui/material";
import { url } from "inspector";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import useConfig from "../../hooks/useConfig";
import { toast } from "react-toastify";
import { APIRoutes } from "../../utils/constants";
import { PersonalChannelApi } from "../PersonalChannel/PersonalChannel.Api";

declare global {
  interface Window {
    bitmovin: any;
  }
}

interface bitMovingPlayerProps {
  playerRef: any;
  currentVideoData: any;
  videoList: any[];
  videoUrl: string;
  setPage: any;
  page: any;
  setCurrentVideo: any;
  pageData: any;
}
const HighlightPlayer: React.FC<bitMovingPlayerProps> = ({
  playerRef,
  currentVideoData,
  videoList,
  setPage,
  page,
  videoUrl,
  setCurrentVideo,
  pageData,
}) => {
  const userDetails = useCommonDetails();
  const [liniusAccessToken, setLiniusAccessToken] = useState({} as any);
  const configData = useConfig();

  const playerId = configData.jwPlayerID;
  // Event handler for seek end
  const GetAccessToken = async () => {
    try {
      const res = await PersonalChannelApi.GetLiniusAccessToken(
        APIRoutes.GetLiniusAccessToken
      );
      if (res?.data?.data) {
        localStorage.setItem(
          "persist:root",
          JSON.stringify({
            authSlice: JSON.stringify({
              accessToken: res?.data?.data.accessToken,
            }),
            userSlice: JSON.stringify({
              apiKey: configData.xApiKey,
            }),
          })
        );
        setLiniusAccessToken(res?.data?.data);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    GetAccessToken();
  }, []);
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((registration) => {
        registration?.active?.postMessage({
          accessToken: liniusAccessToken?.accessToken,
          apiKey: configData.xApiKey,
        });
      });
    }
  }, [liniusAccessToken, configData]);
  useEffect(() => {
    // Function to initialize JW Player

    var conf: any = {
      key: configData?.bitMovinKey,
      location: {
        ui: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
        ui_css: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
      },

      analytics: {
        key: configData.analysticBitmovinKey,

        customUserId: userDetails.UserID,
      },

      playback: {
        autoplay: true,
      },
      cast: {
        enable: true,
      },
    };

    var source = {
      hls: videoUrl,
    };
    Player.addModule(AnalyticsModule);
    playerRef.current = new Player(
      document.getElementById("linus-bitmovin-player") as any,
      conf
    );

    // Setup the player
    playerRef.current.load(source).then(() => {});

    playerRef.current.on(PlayerEvent.PlaybackFinished, () => {
      const findIndex = videoList.findIndex(
        (el: any) => el.ID == currentVideoData.ID
      );
      if (
        pageData.totalPage === pageData.page &&
        findIndex === videoList.length - 1
      ) {
        setPage(1);
        return;
      } else if (findIndex === videoList.length - 1) {
        setPage(page + 1);

        return;
      } else {
        setCurrentVideo(videoList[findIndex + 1]);
        return;
      }
    });

    return () => {
      playerRef.current.unload();
    };
  }, [playerId, videoUrl, videoList, currentVideoData]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box className="player-container" id={"linus-bitmovin-player"}></Box>
    </Box>
  );
};

export default HighlightPlayer;
