import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PlayerCards from "./PlayerCards";
import { APIRoutes } from "../../../../utils/constants";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { UserContext } from "../..";
import { toast } from "react-toastify";
import TeamWithPlayer from "./TeamWithPlayers";
import HttpService from "../../../../hooks/Https-services";

const ChannelCards = () => {
  const [all, setAll] = useState(false);
  const [select, setSelect] = useState(false);
  const [teamName, setTeamName] = useState([]);
  const {
    selectedTeam,
    setSelectedPlayer,
    setSelectedTeam,
    selectedPlayer,
    isSelectAllPlayer,
    setIsSelectAllPlayers,
    isSelectAllEvent,
    setIsSelectAllEvents,
    channel,
  }: any = useContext(UserContext);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [selectAllPlayer, setSelectAllPlayer] = useState(false);
  const handleAll = () => {
    setAll(!all);
    if (select == true) {
      setSelect(false);
    }
  };
  const handleSelect = () => {
    setSelect(!select);
    if (all == true) {
      setAll(false);
    }
  };


  const GetPlayesNameByTeamsName = async () => {
    try {
      const req = await HttpService.post(APIRoutes.GetPlayesNameByTeamsName, {
        teams: selectedTeam,
      });
      if (req) {
        setTeamName(req.data.data);
        const findDuplicatePlayer = req?.data?.data?.map((el: any) =>
          el?.players?.filter((el: any) => selectedPlayer.includes(el))
        );
        const submitArray = [].concat(...findDuplicatePlayer);

        setSelectedPlayer(submitArray);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    GetPlayesNameByTeamsName();
  }, [selectedTeam]);

  return (
    <>
      {isSkeleton ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", py: 10, width: 1 }}
        >
          <CircularProgress
            size={48}
            sx={{
              color: "#bf311a",
            }}
          />
        </Box>
      ) : (
        <>
          {teamName?.length == 0 ? (
            <Typography sx={{ my: 2 }}>Please Select Team</Typography>
          ) : (
            teamName.map((teamname: any) => (
              <TeamWithPlayer teamname={teamname} />
            ))
          )}
        </>
      )}
    </>
  );
};

export default ChannelCards;
