import { Delete } from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Table,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import PageRoutes, { APIRoutes, getUrl } from "../../../../utils/constants";
import moment from "moment";
import useConfig from "../../../../hooks/useConfig";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import HttpService from "../../../../hooks/Https-services";
import { toast } from "react-toastify";
import ImageDialogBox from "../../SliderSettings/ReorderTable/dialog";
const WhizzardTable = ({
  listOfWhizzard,
  GetWhizzard,
}: {
  listOfWhizzard: any[];
  GetWhizzard: () => void;
}) => {
  const configData = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleUnpublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard unpublished successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handlePublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.PublishWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard published successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handleRegenerateHighLight = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await HttpService.post(
        APIRoutes.RegenerateWhizzard.replace(":id", id),
        {}
      );
      toast.success("HighLight regenerated successfully");
      setIsLoading(false);
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };
  const handelDeleteAd = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard deleted successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell> Date</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfWhizzard.map((row: any, index: any) => (
            <TableRow
              key={index + 1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {" "}
              <TableCell align="left">
                <ImageDialogBox
                  src={
                    row?.IsAutomated && !row?.Thumbnail
                      ? getUrl(row?.HighlightUrl)
                      : row?.Thumbnail
                  }
                />
              </TableCell>
              <TableCell align="left">
                {" "}
                {row?.HighlightDate
                  ? moment(row?.HighlightDate).format("DD MMM YYYY hh:mm A")
                  : "Not Available"}
              </TableCell>
              <TableCell align="left">{row?.Title}</TableCell>
              <TableCell align="right">
                <Box
                  sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}
                >
                  {!!row?.IsAutomated &&
                    ["nahl", "dev"].includes(configData.name) && (
                      <LoadingButton
                        loading={isLoading}
                        onClick={() => handleRegenerateHighLight(row.ID)}
                        variant="contained"
                      >
                        <AutorenewIcon />
                      </LoadingButton>
                    )}
                  <button
                    onClick={() => {
                      if (row?.IsActive) {
                        handleUnpublish(row?.ID);
                      } else {
                        handlePublish(row?.ID);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      width: "200px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    {row?.IsActive ? "Move to Trash" : "Make Available"}
                  </button>{" "}
                  <button
                    onClick={() =>
                      navigate(PageRoutes.EditWhizzard.replace(":id", row?.ID))
                    }
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={() => handelDeleteAd(row?.ID)}
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <Delete />
                  </button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default WhizzardTable;
