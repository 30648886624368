import useConfig from "../hooks/useConfig";

// const configData = useConfig();
const PageRoutes = {
  Login: "/login",
  DashBoard: "/dashboard",
  ContactUS: "/contact-us",
  PlayerAlerts: "/admin/player-alerts",
  Whizzard: "/admin/whizzard",
  Media: "/admin/media",
  Ads: "/admin/ads",
  HighLightPlayer: "/highlights",
  Reports: "/admin/reports",

  Polls: "/admin/polls",
  EditAds: "/admin/ads/:id",
  EditPolls: "/admin/polls/:id",
  EditWhizzard: "/admin/whizzard/:id",
  MediaCreate: "/admin/media/:id",
  EditPlayerAlerts: "/admin/player-alerts/edit/:id",
  HomePage: "/",
  Any: "/*",
  Admin: "/admin/announcement",
  GiftPage: "/buy-gift",
  SliderSetting: "/admin/slider",
  EditSliderSetting: "/admin/slider/:id",
  EditAnnouncement: "/admin/announcement/:id",
  UserView: "/admin/user/:id",
  Register: "/register",
  AccountPage: "/account",
  Packages: "/plans",
  UpcomingEvent: "/upcoming-event",
  ChatAPP: "/chat-app",
  OnDemand: "/vod",
  VerifyEmail: "/emailVerify",
  Users: "/admin/users",
  Onboard: "/onboard",

  WatchParty: "/watch-party",
  EmailEditor: "/email-editor",
  EditProfile: "/profile/edit-profile",
  PasswordReset: "/resetPassword",
  TermAndConditions: "/term-conditions",
  PrivacyPolicy: "/privacy-policy",
  Watch: "/watch/:gameId",
  Setting: "/profile",
  paymentPage: "/payment/:clientSecret/:plan_id/:paymentId",
  Alert: "/alert",
  GameCenter: "/game-center",
  MyNAHLTV: "/my-channel/:id/:channel_id",
  PersonalChannel: "/personal-channel/:id",
};

export const APIRoutes = {
  RefreshToken: "/refreshToken",
  CreateSubscription: "/createSubscription",
  UnPublishWhizzard: "/unPublishWhizzard/:id",
  PublishWhizzard: "/publishWhizzard/:id",
  DeleteWhizzard: "/deleteWhizzard/:id",
  GetWhizzards: "/getWhizzards",
  RegenerateWhizzard: "/regenerateWhizzard/:id",
  GetWhizzardsAdmin: "/getWhizzardsAdmin",
  CastVote: "/castVote/:id",
  CreateMedia: "/createMedia",
  CreateWhizzard: "/createWhizzard",

  UnPublishAd: "/unPublishAd/:id",
  PublishMedia: "/publishMedia/:id",
  UnpublishMedia: "/unpublishMedia/:id",
  UpdateWhizzardByID: "/updateWhizzardByID/:id",
  DeleteMedia: "/deleteMedia/:id",
  PublishAd: "/publishAd/:id",
  DeleteAd: "/deleteAdById/:id",
  GetAdDetailsByID: "/getAdbyID/:id",
  GetWhizzardByID: "/getWhizzardByID/:id",

  ApplyCouponToSubscription: "/applyCouponToSubscription",
  ApplyCodeUpgrade: "/applyCodeUpgrade",
  RetrivePendingPrice: "/retrivePendingPrice",

  GetErrorMessagesByID: "/getErrorMessagesByID/:id",
  UpdateErrorMessageByID: "/updateErrorMessageByID/:id",
  UpdatePollByID: "/updatePollByID/:id",

  GetUserAccountDetails: "/getUserAccountDetails",
  CreateAd: "/uploadAdVideo",
  UpdateAd: "/updateAdVideo/:id",

  GetPaymentMethod: "/getPaymentMethod",
  UnPublishPlayerError: "/unPublishErrorMessgae/:id",
  CreateErrorMessage: "/createErrorMessage",
  PublishPlayerError: "/sendMessage/:id",
  GetPollByID: "/getPollById/:id",
  GetPoll: "/getPolls",
  PublishPoll: "/publishPoll/:id",
  UnPublishPoll: "/unPublishPoll/:id",
  DeletePoll: "/deletePoll/:id",
  CreatePoll: "/createPoll",
  GetPollByGameID: "/getPollByGameID",
  GetUserDownloadCredit: "/getUserDownloadCredit",
  DeleteErrorMessage: "/deleteErrorMessage/:id",
  GetCurrentActiveAd: "/getCurrentActiveAd",
  GetAllLinusDownloadVideo: "/getAllLinusDownloadVideo",
  GetAllErrorMessages: "/getAllErrorMessages",
  DeactivateUser: "/deactivateUser/:id",
  DeleteUsersSub: "/deleteUsersSubscription/:id",
  CheckIsVoteGiven: "/checkIsVoteGiven/:id",
  CustomerPortal: "/customer-portal",
  JoinParty: "/joinParty",
  GetAllAds: "/getAllAds",
  getMediaList: "/getMediaList",
  GetRoles: "/getRoles",
  UpdateUserRole: "/updateUserRole/:id",
  UpdateUserPassword: "/updateUserPassword/:id",
  ApplyGiftCode: "/applyGiftCode",
  updateAllSlider: "/updateAllSlider/:status",
  CreateGiftCard: "/createGiftCard",
  GetGiftCardDetails: "/getGiftCardDetails/:id",
  GetUsersEmailDetails: "/getUsersEmailDetails/:id",
  GetUsersFavouriteTeam: "/getUsersFavouriteTeam",
  CreateOrUpdateUserFavouriteTeam: "/createOrUpdateUserFavouriteTeam",
  GetMessageByGameID: "/getErrorMessagesByGameID",
  GetListOfNotification: "/getListOfNotification",
  GetListOfNotificationByID: "/getListOfNotification/:id",
  EditNotification: "/editNotification/:id",
  PublishNotification: "/publishNotification/:id",
  DeleteNotification: "/deleteNotification/:id",
  GetUserAllDownloadedVideoClipsByUserID:
    "/getUserAllDownloadedVideoClipsByUserID/:id",
  GetUserAllVideoClips: "/getUserAllVideoClips",
  getUserAllDownloadedVideoClips: "/getUserAllDownloadedVideoClips",
  DownloadClips: "/downloadClips",
  GetHomeSlider: "/getHomeSlider/:id",
  UpdateHomeSlider: "/home-slider-update/:id",
  UnPublishNotification: "/unPublishNotification/:id",
  UnPublishSlider: "/unPublishSlider/:id",
  PublishSlider: "/publishSlider/:id",
  CreateNotification: "/createNotification",
  getUserDownloadCredit: "/getUserDownloadCredit",

  SubscribePlan: "/create-checkout-session",
  CheckSubscribe: "/is-user-subscription-active",
  GetSubscriptionDetials: "/get-user-subscription-details",
  GetCurrentSubscriptionDetials: "/get-user-current-subscription-details/:id",
  CheckCustomerExistOrNot: "/checkCustomerExistOrNot",
  GetAllUser: "/getAllUsers",
  GetViewReport: "/getViewReport",
  EditProfile: "/editprofile",
  CreateChannel: "/createChannel",
  ChangePassword: "/changePassword",
  GetLiniusAccessToken: "/getLiniusAccessToken",
  GetVideoDetails: "/getVideoDetails",

  GetVideoUrl: "/getVideoUrl",
  GetAllThumbnais: "/getAllThumbnails",
  CreteDownloadVideo: "/creteDownloadVideo",
  CreteDownloadVideoInLive: "/creteDownloadVideoInLive",
  UpdateChannel: "/updateChannel/:id",
  GetChannel: "/getChannel/:id",
  GetAllChannel: "/getAllChannel",
  DeleteChannel: "/deleteChannel/:id",
  GetVideoList: "/getVideoList/:page/:id",
  StartParty: "/startWatchParty",
  Slider: "/home-slider/active",
  GetAllSlider: "/home-slider/all",
  DeleteSliderById: "/delete-home-slider/:id",
  UpdatePaymentMethod: "/updatePaymentMethod",
  GetPaymentHistory: "/getPaymentHistory/:id",
  GetClientSecret: "/getClientSecret",
  GetToken: "/getTokenIVS",
  CreateIntent: "/create-payment-intent",
  SignIn: "/signin",
  InviteSend: "/sendUserInvite",
  PostClipDetailas: "/userVideoClips",
  ShareClipDetailas: "/shareVideoClip",
  PostVideoAnalyse: "/createVideoAnalyse",
  GetVideoClipsDetails: "/getUserVideoClips/:id",
  CustomerSubscriptionHistory: "/customer-subscription-history",
  GoogleSignIn: "/googleauth",
  Login: "/login",
  Register: "/register",
  VerifyEmail: "/verify-email",
  ResetPassword: "/setpassword",
  ForgotPassword: "/forgotpassword",
  SendResetPasswordEmailById: "/sendResetPasswordEmailById/:id",
  UpdateUserDownloadCredit: "/updateUserDownloadCredit/:id",
  GetUserDownloadTokensById: "/getUserDownloadTokensById/:id",
  GetUserDetailsById: "/getUsersByID/:id",
  ChannelOption: "/channel/:id",
  Team: `/team/:customer/:id`,
  CreateSlider: "/create-home-slider",
  HighLights: "/highlight/:id",
  GetTeamHighLights: "/getGoals/:id/:value/:teamData/:action/:page",
  CreateMeeting: "/createMeeting?",
  GetAttendee: "/getAttendee?",
  TeamEventsList: "/schedule",
  SendMeetingInvite: "/sendMeetingInvite",
  DownloadPaymentPdf: "/downloadPaymentPdf",
  GetPlans: "/getPlans",
  GetPlansByID: "/getPlanDetailsById/:id",
  GameDetails: "/player",
  CreateRoom: "/createRoom",
  GetLogs: "/getLogs",
  GetLogsCount: "/getLogsCount",
  MarkAllRead: "/markAllRead",
  MarkAllReadAnnoucement: "/markAllReadAnnoucement",
  GameLogsAdd: "/gameLogAdd",
  UploadImage: "/uploadImage",
  UpdateLogs: "/updateLogs/:id",
  UpdateLogsAnnoucement: "/updateAnnouncement/:id",
  AppyCode: "/applyCode",
  ChangePlanDetails: "/cancelSubscription",
  ResendInvite: "/resendEmailInvite",
  GetAllTeams: "/getAllTeams",
  GetSeasons: "/seasons",
  GetPlayesNameByTeamsName: "/getPlayesNameByTeamsName",
  PauseSubscription: "/pauseSubscription/:id",
  GetEvents: "/getEvents",
  GetPaymentLogs: "/getPaymentLogs",
};

export const UserRoles = {
  USER: 1,
  HTP_ADMIN: 2,
  TEAM_ADMIN: 3,
  LEAGUE_ADMIN: 4,
  FREE_WATCH: 5,
  FREE_WATCH_D: 6,
  CUSTOMER_ADMIN: 7,
};
export const UserPermissions = {
  reguler: "reguler",
  watch: "watch",
  download: "download",
};

export const USERROLE = [
  {
    name: "SUPERADMIN",
    id: 2,
    permissitions: ["normal", "stopwatch", "slider"],
  },
  {
    name: "LEAGUE_ADMIN",
    id: 1,
    permissitions: ["normal", "slider"],
  },
];
export const CATEGORY = [
  { key: "Goal", value: "goal" },
  { key: "Shot", value: "shot" },
  { key: "Save", value: "save" },
  { key: "Highlight", value: "highlight" },
  { key: "Faceoff", value: "faceoff" },
  { key: "Hit", value: "hit" },
  { key: "Penalty", value: "penalty" },
  { key: "Custom", value: "custom" },
];
export const getUrl = (url: string) => {
  const urlObj = new URL(url);
  // Get query parameters
  const assetID = urlObj.searchParams.get("id")?.split(",")[0]; // 'JohnDoe'
  console.log(assetID);
  const time = 1595700;
  return `https://middleware.lvs.linius.com/playback/poster/${assetID}?ts=${time}&size=720p`;
};
export const INSIDER_PLAN_ID = 2;
export default PageRoutes;
