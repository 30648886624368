import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import WhizzardTable from "./WhizzardTable";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ListOfWhizzard = () => {
  const [listOfWhizzard, setListOfWhizzard] = useState<any>([]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const configData = useConfig();

  const GetWhizzard = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetWhizzardsAdmin}?isActive=${value === 0 ? true : false}`
      );
      setListOfWhizzard(res?.data?.data);
    } catch (error) {}
  }, [value]);

  useEffect(() => {
    GetWhizzard();
  }, [GetWhizzard]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Whizzards</Typography>
        <Box>
          <button
            onClick={() =>
              navigate(PageRoutes.EditWhizzard.replace(":id", "0"))
            }
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Add Whizzard
          </button>{" "}
        </Box>
      </Box>
      <Box>
        <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
          <Tab label="Available" {...a11yProps(0)} />
          <Tab label="Trash" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <WhizzardTable
            listOfWhizzard={listOfWhizzard}
            GetWhizzard={GetWhizzard}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WhizzardTable
            listOfWhizzard={listOfWhizzard}
            GetWhizzard={GetWhizzard}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default ListOfWhizzard;
